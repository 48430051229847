import { IonContent, IonPage, IonCheckbox, IonButton, IonToast } from '@ionic/react';
import { cloudUploadOutline } from 'ionicons/icons';
import {
  Header,
  InputCustom,
  SelectCustom,
  FilePickerCustom,
  PhoneInputCustom,
  CardCustom,
} from '../../../../components';
import { useHistory } from 'react-router-dom';
import { AuthRegisterInput } from '../../../../models/gql/graphql';
import { SIGNUP } from '../../../../graphql/mutations/auth.graphql';
import { UPLOAD } from '../../../../graphql/mutations/upload.graphql';
import { useMutation } from '@apollo/client';
import { useState, useRef, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import legalStatusOptions from './options';
import './style.css';
import { omit } from 'lodash';
import { __ } from '../../../../utils/traduction';

const Formulaire: React.FC = () => {
  const history = useHistory();
  const [AuthRegister, { loading }] = useMutation(SIGNUP);
  const [upload, { loading: loadingUpload }] = useMutation(UPLOAD);
  const refCheckBox = useRef(null);
  const methods = useForm<AuthRegisterInput>();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;
  const [errorsMessage, setErrorsMessage] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [valueData, setValueData] = useState<AuthRegisterInput>();
  const [valueSelect, setValueSelect] = useState<string>('');
  register('kbis', { required: true });
  register('identityDocument', { required: true });
  register('phone', { required: true });
  register('email', { required: true });
  register('acceptNewsletter', { value: false });

  //HandleChange function into form
  const onSubmit = async (dataSubmited: AuthRegisterInput) => {
    try {
      const { data: dataKbis } = await upload({ variables: { file: dataSubmited.kbis } });
      const { data: dataIdentityDocument } = await upload({ variables: { file: dataSubmited.identityDocument } });
      const dataToPostWithFiles = {
        ...omit(dataSubmited, ['kbis', 'identityDocument']),
        kbis: dataKbis?.upload?.data?.id,
        identityDocument: dataIdentityDocument?.upload?.data?.id,
        isPro: true,
        email: dataSubmited.email?.toLowerCase(),
      };
      const { data: dataRegister } = await AuthRegister({ variables: { input: dataToPostWithFiles } });
      history.push(`/signup/verification/${dataSubmited?.phone}/pro`);
    } catch (err: any) {
      setIsOpen(true);
      setErrorsMessage(__(err.message));
    }
  };

  const handleChangeFile = (attr: any, file: File) => {
    setValue(attr, file as any);
  };

  // To override shadow-root (open)
  useEffect(() => {
    if (refCheckBox && refCheckBox.current) {
      const current: any = refCheckBox?.current;
      setTimeout(() => {
        current?.shadowRoot?.querySelector('.label-text-wrapper')?.setAttribute('style', 'white-space:normal');
      }, 1000);
    }
  }, []);

  const handleChangeData = (attr: string, val: any) => {
    setValueData({ ...valueData, [attr]: val.detail.value });
  };
  const handleChangeSelect = (e: any) => {
    console.log({ e });
    setValueSelect(e.detail.value);
  };

  return (
    <FormProvider {...methods}>
      <IonPage>
        <Header title="Inscription" noButtonBack={false} />
        <IonContent className="ion-padding">
          <div style={{ marginBottom: 350 }}>
            <CardCustom>
              <form onSubmit={handleSubmit(onSubmit)}>
                <InputCustom
                  value={valueData?.companyName}
                  handleChange={(e) => handleChangeData('companyName', e)}
                  label="Entreprise*"
                  placeholder="Fnac"
                  attr="companyName"
                />
                <InputCustom
                  value={valueData?.siret}
                  handleChange={(e) => handleChangeData('siret', e)}
                  label="Numéro de SIRET*"
                  placeholder="123 568 941 00056"
                  attr="siret"
                  type="number"
                />
                <SelectCustom
                  label="Forme juridique*"
                  placeholder="Sélectionnez une forme juridique"
                  options={legalStatusOptions}
                  attr="legalStatus"
                  defaultValue={valueSelect}
                  handleChange={handleChangeSelect}
                />
                <div>
                  <FilePickerCustom
                    label="KBIS*"
                    icon={cloudUploadOutline}
                    handleChange={(file) => handleChangeFile('kbis', file)}
                  ></FilePickerCustom>
                  {errors.kbis && <p className="errorDescription">Kbis est requis</p>}
                </div>
                <div>
                  <FilePickerCustom
                    label="Pièce d'identité*"
                    icon={cloudUploadOutline}
                    handleChange={(file) => handleChangeFile('identityDocument', file)}
                  ></FilePickerCustom>
                  {errors.identityDocument && <p className="errorDescription">La piece d'identite est requise</p>}
                </div>
                <InputCustom
                  value={valueData?.lastname}
                  handleChange={(e) => handleChangeData('lastname', e)}
                  label="Nom"
                  placeholder="Doe"
                  attr="lastname"
                />
                <InputCustom
                  value={valueData?.firstname}
                  handleChange={(e) => handleChangeData('firstname', e)}
                  label="Prenom"
                  placeholder="John"
                  attr="firstname"
                />
                <InputCustom
                  value={valueData?.job}
                  handleChange={(e) => handleChangeData('job', e)}
                  label="Poste"
                  placeholder="Responsable RH"
                  attr="job"
                />
                <div>
                  <PhoneInputCustom
                    label="Numero de téléphone"
                    handleChange={(data: string) => setValue('phone', data)}
                  ></PhoneInputCustom>
                  {errors.phone && <p className="errorDescription">Le numero de téléphone est requis</p>}
                </div>
                <InputCustom
                  value={valueData?.email}
                  handleChange={(e) => handleChangeData('email', e)}
                  label="Adresse email*"
                  placeholder="mail@gmail.com"
                  attr="email"
                  type="email"
                />
                <div className="ion-margin-bottom">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IonCheckbox
                      {...register('acceptCGU', { required: true, setValueAs: (v) => (v === 'on' ? false : v) })}
                      defaultChecked={true}
                      mode="md"
                      className=" font-outfit"
                      color="dark"
                      labelPlacement="end"
                      onIonChange={(e) => setValue('acceptCGU', e.detail.checked ? e.detail.checked : null)}
                    >
                      J’accepte les
                    </IonCheckbox>
                    <a
                      href="https://www.tedispo.com/mention-legale-confidentialite"
                      onClick={() => console.log('click')}
                      style={{ textDecoration: 'none', marginLeft: 5 }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      CGU
                    </a>
                  </div>
                  {errors.acceptCGU && (
                    <p className="errorDescription">Vous devez accepter les Conditions Générales d'Utilisation</p>
                  )}
                </div>
                <div>
                  <IonCheckbox
                    className="ion-margin-bottom font-outfit wrap-text"
                    labelPlacement="end"
                    mode="md"
                    color="dark"
                    onIonChange={(e) => setValue('acceptNewsletter', e.detail.checked)}
                    ref={refCheckBox}
                  >
                    Je souhaite m’abonner à la newsletter et à l’offre commerciale
                  </IonCheckbox>
                </div>

                <IonButton
                  disabled={loading || loadingUpload}
                  type="submit"
                  className="ion-margin-top"
                  expand="block"
                  color="primary"
                >
                  Continuer
                </IonButton>
              </form>
            </CardCustom>
          </div>
        </IonContent>
        <IonToast
          isOpen={isOpen}
          message={__(errorsMessage)}
          onDidDismiss={() => setIsOpen(false)}
          duration={5000}
          color="danger"
        ></IonToast>
      </IonPage>
    </FormProvider>
  );
};

export default Formulaire;
