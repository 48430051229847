import { useLazyQuery, useMutation } from '@apollo/client';
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRow,
  IonSkeletonText,
  IonSpinner,
  IonTitle,
  IonToolbar,
  IonRippleEffect,
} from '@ionic/react';
import { arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import messageSvg from '../../../assets/icons/message.svg';
import repportSvg from '../../../assets/icons/report.svg';
import userCheck from '../../../assets/icons/user-check.svg';
import warningSvg from '../../../assets/icons/warning.svg';
import { REPORTING_LIST } from '../../../constants';
import {
  BLOCKED_USER,
  CREATE_USER_FOLLOWER,
  DELETE_USER_FOLLOWER,
  UNBLOCKED_USER,
} from '../../../graphql/mutations/updateUser..graphql';
import { GET_RESTRICT_RELATION_USER_FOLLOWER } from '../../../graphql/queries/userFollower.graphql';
import { useDispo, useToast } from '../../../hooks';
import { getUserSubscription } from '../../../hooks/useUserFollower';
import {
  Enum_Report_Type,
  Enum_Userfollower_Status,
  InputMaybe,
  UserFollowerEntity,
  UsersPermissionsMe,
  UsersPermissionsUser,
} from '../../../models/gql/graphql';
import { getUserId } from '../../../utils';
import AvatarUser from '../../AvatarUser';
import MenuList from '../../MenuList';
import { ComponentModal, ConfirmModal } from '../../Modals';
import Reporting from '../../Reporting';

interface IProfilAction {
  id: string;
  scrollTop: number;
  isBloked?: boolean;
  refetchUser: () => void;
  dataUser: UsersPermissionsUser | UsersPermissionsMe;
  isBlockedByMe?: boolean;
  relationInfo: IRelation;
  relations: UserFollowerEntity[];
  loadingRelation: boolean;
  setButtonActionLoading: (id: boolean) => void;
  loadingUserData: boolean;
  isMyProfil: boolean;
  userConnected: UsersPermissionsMe;
}

const ProfilAction: React.FC<IProfilAction> = ({
  dataUser: userData,
  id,
  scrollTop,
  isBloked,
  refetchUser: refetchUserData,
  isBlockedByMe,
  relationInfo,
  relations,
  loadingRelation,
  setButtonActionLoading,
  loadingUserData,
  isMyProfil,
  userConnected,
}) => {
  const { setRedirectHome } = useDispo();
  const location = useLocation();
  const [isBlockedModalOpen, setBlockedModal] = useState(false);
  const [openChoiceBlocked, setOpenChoiceBlocked] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [modalItems, setModalItems] = useState<IlistItem[]>({} as IlistItem[]);
  const [userId, setUserId] = useState<string>('0');
  const connectedId = getUserId()?.toString();
  const history = useHistory();

  const [blockedUser, { loading: loadingBlocked }] = useMutation(BLOCKED_USER);
  const [createFollowerUser, { loading: loadingAddFollower }] = useMutation(CREATE_USER_FOLLOWER);
  const [deleteFollowerUser, { loading: loadingDelFollower }] = useMutation(DELETE_USER_FOLLOWER);
  const [unblockedUser, { loading: loadingUnblocked }] = useMutation(UNBLOCKED_USER);
  const [getSubscription] = useLazyQuery(GET_RESTRICT_RELATION_USER_FOLLOWER);
  const [listItems, setListItems] = useState<IlistItem[]>([]);
  const toast = useToast();

  if (isMyProfil) {
    userData = userConnected;
  }
  const goBack = () => {
    history.go(-1);
  };

  const openPage = (item: IlistItem) => {
    history.push(item.path);
  };

  const openModal = (action: string) => {
    setModalItems(getModalItem(action));
    setOpenChoiceBlocked(true);
  };

  const doAction = (action: string) => {
    if (action === 'goToMesInfos') {
      history.push('/main/my-account/menu/infos');
    } else if (action === 'followRequest') {
      handleCreateFollowerUser(Enum_Userfollower_Status.Asked);
    } else if (action === 'follow') {
      handleCreateFollowerUser(Enum_Userfollower_Status.Accepted);
    } else {
      openModal(action);
    }
  };

  const goToChat = () => {
    history.push(`/main/chat/${id}`);
  };

  const handleBlockedUser = async () => {
    setButtonActionLoading(true);
    await blockedUser({
      variables: {
        id: userId || '0',
      },
    });
    toast.success('Utilisateur bloqué avec succès');
    reset();
  };

  const handleCreateFollowerUser = async (status: InputMaybe<Enum_Userfollower_Status>) => {
    setButtonActionLoading(true);
    const { data } = await getSubscription({
      variables: {
        filters: {
          and: [
            {
              follower: { id: { eq: connectedId } },
            },
            {
              user: {
                id: { eq: id },
              },
            },
            { status: { eq: status } },
          ],
        },
        pagination: { limit: 500 },
      },
    });

    const exist = data?.userFollowers?.data.length;
    if (!exist) {
      await createFollowerUser({
        variables: {
          data: {
            follower: connectedId || '0',
            user: id || '0',
            status: status,
          },
        },
      });
    }
    reset();
  };

  const handleUnblockedUser = async () => {
    setButtonActionLoading(true);
    try {
      await unblockedUser({
        variables: {
          id: userId || '0',
        },
      });
    } catch (e) {
      console.log('error', e);
    }
    toast.info('Utilisateur débloqué avec succès', 3000, userCheck);
    reset();
  };

  const handleUnfollowUser = async () => {
    setButtonActionLoading(true);
    if (relations?.length) {
      await deleteFollowerUser({
        variables: {
          id: relations[0].id || '0',
        },
      });
    }
    toast.info('Désabonnement confirmé', 3000);
    reset();
  };

  const reset = async () => {
    setBlockedModal(false);
    setOpenChoiceBlocked(false);
    await refetchUserData();
  };

  const getModalItem = (type: string) => {
    let blockedList: IlistItem[] = !isBlockedByMe
      ? [{ label: 'Bloquer', path: '', action: () => setBlockedModal(true), showIcon: true }]
      : [];

    if (type === 'openUnfollowModal') {
      //private profil
      blockedList.unshift({
        label: 'Ne plus suivre',
        path: '',
        action: handleUnfollowUser,
        showIcon: true,
      });
    } else if (type === 'openUnfriendModal') {
      //public profil
      blockedList.unshift({ label: 'Se désabonner', path: '', action: handleUnfollowUser, showIcon: true });
    } else if (type === 'unblocked') {
      blockedList.unshift({ label: 'Débloquer', path: '', action: handleUnblockedUser, showIcon: true });
    }
    return blockedList;
  };

  useEffect(() => {
    const initialiseListItems = async () => {
      if (!!id && id !== '0') {
        if (!!id && !listItems.length && id?.toString() === connectedId) {
          let items = await getUserSubscription(userConnected);
          setListItems(items);
        }
      }
    };
    initialiseListItems();
  }, [id, userConnected]);

  useEffect(() => {
    if (!!id && id !== '0') {
      setUserId(id);
    }
  }, []);

  return (
    <IonGrid>
      <IonRow className="full-width">
        <IonCol size="auto">
          <IonButton fill="clear" onClick={goBack}>
            <IonIcon slot="start" icon={arrowBackOutline} className="text-white ion-no-margin"></IonIcon>
          </IonButton>
        </IonCol>
        <IonCol size="8" className="text-center">
          <IonTitle className="text-center">
            {userId !== connectedId && loadingUserData && (
              <IonRow className="ion-align-items-center ion-justify-context-center">
                <IonSkeletonText animated style={{ width: '60%' }} />
                <IonSkeletonText animated style={{ width: '30%' }} />
                <IonSkeletonText animated style={{ width: '20%' }} />
              </IonRow>
            )}
            <span className="title-only font-outfit text-white">
              {!userId || (userId && userId === connectedId)
                ? 'Ton Profil'
                : userData?.isPro
                ? userData?.companyName
                : userData?.username}
            </span>
          </IonTitle>
        </IonCol>
        {userId && userId !== connectedId && (
          <IonCol size="1.5" className="text-center">
            <IonButton fill="clear" onClick={() => setIsOpenModal(true)}>
              <IonIcon icon={repportSvg}></IonIcon>
            </IonButton>
          </IonCol>
        )}
      </IonRow>
      {!scrollTop && (
        <div>
          <IonRow className="text-center ion-align-items-center">
            <IonCol size="2">
              {isMyProfil ? (
                <AvatarUser userMe={userData as UsersPermissionsMe} size="size-md"></AvatarUser>
              ) : (
                <AvatarUser user={userData as UsersPermissionsUser} size="size-md"></AvatarUser>
              )}
            </IonCol>

            <IonCol>
              <IonButton
                className={`ion-no-border relative ion-bg-white text-blue text-bold not-transorm-text`}
                size="small"
                onClick={() => relationInfo.action && doAction(relationInfo.action)}
                style={{ height: '35px', width: '80%' }}
                disabled={
                  loadingRelation ||
                  loadingBlocked ||
                  loadingAddFollower ||
                  loadingDelFollower ||
                  loadingUnblocked ||
                  (isBloked && relationInfo.action !== 'unblocked')
                }
              >
                <IonLabel text-wrap>
                  <IonRow className="font-outfit text-size-xs">
                    <IonCol>{relationInfo.text}</IonCol>
                    {!!relationInfo.icon && (
                      <IonCol size="1">
                        <IonIcon icon={relationInfo.icon}></IonIcon>
                      </IonCol>
                    )}
                  </IonRow>
                </IonLabel>
                {!!loadingRelation && <IonSpinner color="dark" className={'custom-loader'}></IonSpinner>}
              </IonButton>
            </IonCol>
            {!!relationInfo.hasMessageIcon && (
              <IonCol size="2">
                <div onClick={goToChat} className="bg-white border-radius-10 padding-5">
                  <IonIcon icon={messageSvg}></IonIcon>
                </div>
              </IonCol>
            )}
          </IonRow>
          <div>
            {!(userId && userId !== connectedId) && (
              <>
                {listItems.length ? (
                  <>
                    {listItems.map((item, index) => (
                      <IonLabel
                        text-wrap
                        key={index}
                        className=""
                        onClick={async (e) => {
                          e.preventDefault();
                          history.push(item.path, { data: item.data });
                        }}
                      >
                        <IonRow className="border-white-bottom ion-activatable ripple-parent rectangle">
                          <IonCol className={`text-white font-outfit font-weight-400`}>
                            <IonRow>{item.label}</IonRow>
                            {!!item.description && <IonRow className="text-size-xs">{item.description}</IonRow>}
                          </IonCol>
                          {item.showIcon && (
                            <IonCol className={`text-white margin-r-5 text-size-16`} size="1">
                              <IonIcon icon={arrowForwardOutline}></IonIcon>
                            </IonCol>
                          )}
                        </IonRow>
                      </IonLabel>
                    ))}
                  </>
                ) : (
                  <>
                    <IonSkeletonText animated className="skeleton-avatar" style={{ width: '100%', height: '100%' }} />
                    <IonSkeletonText animated className="skeleton-avatar" style={{ width: '50%', height: '100%' }} />
                    <IonSkeletonText animated className="skeleton-avatar" style={{ width: '80%', height: '100%' }} />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <ConfirmModal
        isOpen={isBlockedModalOpen}
        title="Es-tu sûr de vouloir bloquer cet utilisateur ?"
        text="Il ne pourra plus voir ton profil ni te contacter"
        okText="Confirmer"
        cancelText="Annuler"
        iconPerso={warningSvg}
        onConfirm={handleBlockedUser}
        onCancel={() => setBlockedModal(false)}
        height="380px"
      />
      <ComponentModal isOpen={openChoiceBlocked} onCancel={() => setOpenChoiceBlocked(false)} height="275px">
        <IonHeader>
          <IonToolbar className="bg-gray no-radius text-blue">
            <IonTitle className="text-center ion-text-capitalize font-outfit">{userData?.username}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <MenuList
            listItems={modalItems}
            icon={arrowForwardOutline}
            hasBorderBottom={true}
            action={(item: IlistItem) => openPage(item)}
            borderColor="black"
          />
          <IonButton className="cancel text-black" fill="clear" expand="block" onClick={() => reset()}>
            Annuler
          </IonButton>
        </IonContent>
      </ComponentModal>
      <Reporting
        actionCancel={() => setIsOpenModal(false)}
        listItems={REPORTING_LIST}
        isOpen={isOpenModal}
        contentTypeReported={Enum_Report_Type.Utilisateur}
        title="Pour quel motif veux-tu effectuer ce signalement ?"
        relatedId={userId}
      ></Reporting>
    </IonGrid>
  );
};

export default ProfilAction;
